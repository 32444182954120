import { useState, useContext, useEffect } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button } from "@mui/material";
import prevArrow from "../Assets/Images/prev-arrow.svg";
import nextArrow from "../Assets/Images/next-arrow.svg";
// import AssessmentProgressContext from "../Store/AssessmentProgressContext";

const PreAdditionalInfo = () => {

  const { id }                          = useParams();
  const navigate                        = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  // const assessProgressCtx               = useContext(AssessmentProgressContext);

  // useEffect(() => {
  //   assessProgressCtx.updateMainSectionsStatus(assessProgressCtx?.mainSections?.map(sec => {
  //     if (sec.title === "Response") return { ...sec, status: "finished" };
  //     if (sec.title === "Relevance") return { ...sec, status: "active" };
  //     else return { ...sec, status: "unfinished" };
  //   }));
  // }, []);

  const nextPage = () => {
    // if (!searchParams.get("leader")) {
    //   navigate("/pre-additional-questions/" + id + "?" + searchParams, { replace: true });
    // } else navigate("/details/" + id + "?" + searchParams, { replace: true });
    navigate("/assessment-additional/" + id + "?" + searchParams, { replace: true });
  }

  const prevPage = () => {
    // if (!searchParams.get("leader")) {
    //   navigate("/assessment/" + id + "/review", { replace: true });
    // } else navigate("/assessment/" + id + "/review?" + searchParams, { replace: true });
    navigate("/details/" + id + "?" + searchParams, { replace: true });
  }

  return (
    <div className="container pre-additional-info">
      <h1>Thanks, you're almost done!</h1>
      <div className="text-container">
        <p>This next section asks 6 additional questions that will help determine your Strategic Alignment.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
      </div>
      {/* <Link to={assessmentLink()} className="cta-link"><Button className="cta">CONTINUE</Button></Link> */}
      <div className="step-buttons">
        <Button className="cta-prev" onClick={() => prevPage()}>
          <img src={prevArrow} alt="Previous Page Arrow" />&nbsp;&nbsp;PREV
        </Button>
        <Button className="cta" onClick={() => nextPage()}>CONTINUE</Button>
      </div>
    </div>
  )
}


export default PreAdditionalInfo;
